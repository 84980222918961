import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"

const PageContent = () => {
  const articles = [
    {
      title: "COMPANY PROFILE",
      content: "A little bit more about us.",
      link: "/about/company-profile",
    },
    {
      title: "CAPABILITIES",
      content: "What we can do.",
      link: "/about/capabilities",
    },
    {
      title: "PLANT",
      content: "The equipment we use.",
      link: "/about/plant",
    },
  ]

  return (
    <div className="container">
      <div className="page-content">
        <p>
          Established in 1992, Inductabend has amassed considerable experience
          delivering high integrity induction bending backed by advanced
          processes.
        </p>
        <div className="article-group">
          {articles.map(article => (
            <div
              key={article.title}
              className="article"
              style={{ textAlign: "center" }}
            >
              <h3>{article.title}</h3>
              <p>{article.content}</p>
              <div className="article-action">
                <Link to={article.link}>
                  <FontAwesomeIcon icon={faPlay} />
                  READ MORE
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PageContent
