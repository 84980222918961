import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/about"
import BgImage from "../../assets/images/2017/08/oil-rig-australia-slide.jpg"

const IndexPage = () => {
  const title = "About Us"
  const content = "A world leader for 25 years and counting"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default IndexPage
